import React from 'react';
import Container from '../components/Container';
import SEO from '../components/seo';
import BlogDetail from '../components/PagesComponent/BlogDetail/BlogDetail';

const BlogDetailSingle = () => {
  return (
    <Container>
      <SEO
        title="Hairun Technology - Actualité"
        description="Details Actualite"
      />
      <BlogDetail />
    </Container>
  );
};

export default BlogDetailSingle;
