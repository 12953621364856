import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    maxWidth: 1800,
    display: 'flex',
    '@media (max-width: 992px)': {
      flexWrap: 'wrap',
    },
    '& > div': {
      paddingTop: 64,
      paddingBottom: 64,
      '@media (max-width: 992px)': {
        paddingTop: 15,
        paddingBottom: 15,
      },
    },
  },
  main: {
    flex: 1,
    paddingRight: 40,
    maxWidth: 1180,
    '@media (max-width: 992px)': {
      paddingRight: 0,
    },
  },
  actualite: {},
  card: {
    boxShadow: 'none',
  },
  meta: {
    fontSize: '0.75rem',
    marginBottom: 15,
    '& svg': {
      fontSize: '0.825rem',
      marginRight: 10,
    },
    '& span': {
      textTransform: 'uppercase',
      backgroundColor: '#F5381A',
      color: '#fff',
      borderRadius: 3,
      padding: '0px 9px',
      marginRight: 10,
      fontSize: '0.625rem',
    },
  },
  media: {
    marginBottom: 30,
    '& img': {
      width: '100%',
      visibility: 'hidden',
    },
  },
  header: {},
  title: {
    fontSize: '2rem',
    lineHeight: '37px',
    fontWeight: 600,
  },
  content: {
    padding: 0,
    fontSize: '1rem',
    letterSpacing: 0.5,
    '& h2': {
      fontSize: '1.25rem',
      fontWeight: 'normal',
    },
    '& ul, & ol': {
      '& li': {
        marginBottom: 20,
      },
    },
    '& a': {
      textDecoration: 'none',
      color: '#1DA1F2',
    },
  },
}));
