import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import styles from './style';

import IconFacebook from '../Icons/IconFacebook';
import IconTwitter from '../Icons/IconTwitter';
import IconLinkedin from '../Icons/IconLinkedin';
import IconYoutube from '../Icons/IconYoutube';

interface PropTypes {
  htmlColor: string;
}
const Share = ({ htmlColor }: PropTypes) => {
  const classes = styles();

  return (
    <Box className={classes.share}>
      <Typography className={classes.text}>Partager sur</Typography>
      <Link href="" color="inherit" underline="none">
        <IconFacebook
          htmlColor={htmlColor}
          viewBox="0 0 32 32"
          width="32"
          height="32"
        />
      </Link>
      <Link href="" color="inherit" underline="none">
        <IconTwitter
          htmlColor={htmlColor}
          width="32"
          height="32"
          viewBox="0 0 32 32"
        />
      </Link>
      <Link href="" color="inherit" underline="none">
        <IconLinkedin
          htmlColor={htmlColor}
          width="32"
          height="32"
          viewBox="0 0 32 32"
        />
      </Link>
      <Link href="" color="inherit" underline="none">
        <IconYoutube
          htmlColor={htmlColor}
          viewBox="0 0 32 32"
          width="32"
          height="32"
        />
      </Link>
    </Box>
  );
};

export default Share;
