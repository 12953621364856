import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  sidebar: {
    width: '30%',
    minWidth: '348px',
    '@media (max-width: 992px)': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  titreSectionActus: {
    fontFamily: 'Josefin Sans',
    fontSize: '2.625rem !important',
    marginBottom: '0 !important',
  },
}));
