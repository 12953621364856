import React from 'react';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';
import { Paper, Box, Typography, Button } from '@material-ui/core';

import styles from './style';
import { TransitionLink as Link } from '../../../Common/TransitionLink/TransitionLink';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import classnames from 'classnames';

interface IActusSidebar {
  image: string;
  key: number;
  title: string;
  intro: string;
  url?: string;
  date?: string;
}

const ActusSidebar = ({
  image,
  key,
  title,
  intro,
  url,
  date,
}: IActusSidebar) => {
  const classes = styles({ image });

  return (
    <Paper elevation={3} className={classes.paper} key={key}>
      {url && (
        <Link to={url} title={title.replace(/&nbsp;/g, ' ')}>
          <Box className={classes.actusContent}>
            <Box display="flex" alignItems="center" className={classes.meta}>
              <span>Publié</span>
              <CalendarTodayIcon />
              {date}
            </Box>
            <Typography variant="h2" className={classes.textElipsis}>
              {title.replace(/&nbsp;/g, ' ')}
            </Typography>
            <Box
              dangerouslySetInnerHTML={{ __html: intro }}
              className={classnames(
                'textParagraphe',
                classes.textElipsis,
                classes.textDescription,
              )}
            />
          </Box>
          <Box className={classes.imgActus}>
            <img src={image} alt=" Images actus" />
          </Box>
        </Link>
      )}
    </Paper>
  );
};

export default ActusSidebar;
