import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'inline-block',
    boxShadow: 'none',
    '@media (max-width: 992px)': {
      width: '100%',
    },
    '@media(max-width:576px)': {
      maxWidth: '100%',
      height: 'auto',
    },
    '&>a': {
      display: 'flex',
      textDecoration: 'none',
      '@media (max-width: 992px)': {
        flexWrap: 'wrap',
      },
    },
    '&>*': {
      '@media(max-width:576px)': {},
    },
    '&:last-child': {
      '@media (min-width: 992px)': {
        flexDirection: 'column-reverse',
      },
      height: 'inherit',
      '&>*': {
        maxWidth: '100%',
      },
    },
  },
  imgActus: (props: any) => ({
    // background: `url(${props.image}) left center /cover no-repeat`,
    width: 202,
    minWidth: '30%',
    order: 1,
    '& img': {
      width: '100%',
      '@media(max-width:576px)': {
        maxWidth: '100%',
        width: '100%',
        display: 'block',
        // background: 'none',
      },
    },
    '@media(max-width:576px)': {
      minWidth: '100%',
      width: '100%',
      marginBottom: 15,
    },
  }),
  actusContent: {
    display: 'flex',
    flexDirection: 'column',
    color: '#272727',
    order: 2,
    paddingLeft: 15,

    '& h2': {
      marginBottom: theme.spacing(2),
      fontWeight: 600,
      fontSize: '1.125rem',
      fontFamily: 'ubuntu',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
      overflow: 'hidden',
      letterSpacing: '0.14px',
      '@media (max-width: 1199px)': {
        fontSize: '1rem',
      },
      '@media (max-width: 576px)': {
        fontSize: '0.825rem',
      },
    },
    '& p': {
      fontSize: '1rem',
      color: '#808080',
      margin: 0,
      '@media (max-width: 576px)': {
        fontSize: '0.825rem',
      },
    },
    '& a': {
      width: 'auto',
      color: 'inherit',
      textDecoration: 'none',
      textTransform: 'uppercase',
      marginTop: 'auto',
      '& button': {
        borderRadius: 4,
        borderColor: '#D34246',
        color: '#D34246',
        transition: '50ms ease-in',
        '@media(max-width:576px)': {
          marginTop: theme.spacing(4),
        },
        '&:hover': {
          borderColor: '#D34246',
          paddingLeft: 20,
          paddingRight: 20,
          transition: '50ms ease-in',
        },
        '& svg': {
          transition: '50ms ease-in',
        },
        '&:hover svg': {
          transition: '50ms ease-in',
          transform: 'translateX(5px)',
        },
      },
    },
    '@media(max-width:576px)': {
      minWidth: '100%',
      paddingLeft: 0,
      alignItems: 'flex-start',
    },
  },
  meta: {
    fontSize: '0.75rem',
    marginBottom: 15,
    '& svg': {
      fontSize: '0.825rem',
      marginRight: 10,
    },
    '& span': {
      textTransform: 'uppercase',
      backgroundColor: '#F5381A',
      color: '#fff',
      borderRadius: 3,
      padding: '0px 9px',
      marginRight: 10,
      fontSize: '0.625rem',
    },
  },
  textElipsis: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    '@media(max-width:576px)': {
      '-webkit-line-clamp': 3,
    },
  },
  textDescription: {
    '@media (min-width:993px)': {
      display: 'none',
    },
  },
}));
