import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';

import styles from './style';
import ActusSidebar from '../../Common/Sidebar/ActusSidebar';
import classnames from 'classnames';

const Sidebar = () => {
  try {
    const data: {
      allWpPost: {
        edges: [
          {
            node: {
              id: string;
              title: string;
              excerpt: string;
              slug: string;
              date: string;
              featuredImage: {
                node: {
                  sourceUrl: string;
                };
              };
            };
          },
        ];
      };
    } = useStaticQuery(graphql`
      query(
        $fields: [WpPostFieldsEnum] = date
        $order: [SortOrderEnum] = DESC
      ) {
        allWpPost(sort: { order: $order, fields: $fields }) {
          edges {
            node {
              title
              excerpt
              slug
              date(formatString: "DD MMMM yyyy", locale: "fr-FR")
              featuredImage {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `);
    // {
    //       allWpPost: {
    //         edges: [
    //           {
    //             node: {
    //               id: '',
    //               date: '',
    //               excerpt: '',
    //               featuredImage: { node: { sourceUrl: '' } },
    //               slug: '',
    //               title: '',
    //             },
    //           },
    //         ],
    //       },
    //     };
    const classes = styles();
    return (
      <Box className={classes.sidebar}>
        <Typography
          variant="h4"
          className={classnames('titreSection', classes.titreSectionActus)}
        >
          <span>Articles</span> Récents
        </Typography>
        {data.allWpPost.edges.map(({ node }, index) => {
          if (index < 4) {
            const image = node.featuredImage
              ? node.featuredImage.node.sourceUrl
              : '';
            return (
              <ActusSidebar
                key={+index}
                title={node.title}
                image={image}
                intro={node.excerpt}
                date={node.date}
                url={`/blog/${node.slug}`}
              />
            );
          }
        })}
      </Box>
    );
  } catch (err) {
    console.log('erreur');
    return <></>;
  }
};

export default Sidebar;
